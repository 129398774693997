import { acceptHMRUpdate, defineStore } from 'pinia'
import axios from 'axios'
import type { InsuranceCategory } from '~/store/insurances'
import type { Article, CmsImageContent } from '~/store/blog'
import type { InsuranceCompany } from '~/store/facility'

export const loremIpsum = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'

export interface Product {
  id: number
  slug: string
  title: string
  content: string
  status: string
  seo_slug: string | null
  seo_description: string | null
  seo_meta_keywords: string | null
  images: {
    image: CmsImageContent
  }
  extras: {
    image_alt_text: string
  }
  insurance_categories: InsuranceCategory[]
  insurance_companies: InsuranceCompany[]
  related_recommended_products: Product[]
  related_articles: Article[]
}

export interface ProductState {
  homeProducts: Product[]
  homeProductsDownloaded: boolean
  products: Product[]
  productsDownloaded: boolean
  singleProduct: null | Product
  page: number
}

export const useProductsStore = defineStore('products', {
  state(): ProductState {
    return {
      homeProducts: [],
      homeProductsDownloaded: false,
      products: [],
      productsDownloaded: false,
      singleProduct: null,
      page: 1,
    }
  },
  actions: {
    async getHomeRecommendedProductsFromAPI() {
      if (this.homeProductsDownloaded) {
        return;
      }

      const response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/homepage/recommended-products`)

      this.homeProducts = response.data.data
      this.homeProductsDownloaded = true
    },
    async getRecommendedProductsFromAPI() {
      if (this.productsDownloaded) {
        return;
      }

      const products = await axios.get(`${import.meta.env.VITE_API_URL}/v1/recommended-products`)

      this.products = products.data.data
      this.productsDownloaded = true
    },
    async getMoreRecommendedProducts() {
      this.page++;
      const response = await axios.get(`${import.meta.env.VITE_API_URL}/v1/recommended-products?page=${this.page}`)

      if (response.data.data.length > 0) {
        this.products = [...this.products, ...response.data.data]
      }

      return response.data.data.length;
    },
    async getRecommendedProductBySlugFromAPI(slug) {
      const singleProduct = await axios.get(`${import.meta.env.VITE_API_URL}/v1/recommended-products/${slug}`)

      this.singleProduct = singleProduct.data.data
    },
  },
  getters: {
    getProductById: (state: ProductState) => (id: number) => state.products && state.products.find(v => v.id === Number(id)),
    getProductBySlug: (state: ProductState) => (slug: string) => state.products && state.products.find(v => v.slug === slug),
    getProducts: (state: ProductState) => state.products && state.products,
    getProductsLimitedByNumber: (state: ProductState) => (number: number) => state.products && state.products.slice(0, number),
    getHomeProductsLimitedByNumber: (state: ProductState) => (number: number) => state.homeProducts && state.homeProducts.slice(0, number),
    getSingleProduct: (state: ProductState) => state.singleProduct && state.singleProduct,
  },
  persist: false,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProductsStore, import.meta.hot))
}
