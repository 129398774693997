<template>
  <div
    class="typography-header-layout"
    :class="[{'typography-header-layout--inline' : inline}, {'typography-header-layout--fullscreen' : fullscreen}]"
  >
    <div
      :class="[readMore ? 'typography-header-layout__read-more-text' : 'typography-header-layout__only-text', {'typography-header-layout__only-text--inline' : left}]"
    >
      <h2
        :class="[
          text1Primary ? 'text-primary' : 'text-secondary',
          text1Small ? 'heading-secondary' : 'heading-primary'
        ]"
      >
        {{ text1 }}
      </h2>
      <span
        v-if="text2"
        :class="[
          text2Primary ? 'text-primary' : 'text-secondary',
          text2Small ? 'heading-secondary' : 'heading-primary'
        ]"
      >&nbsp;{{ text2 }}</span>
      <span
        v-if="text3"
        :class="[
          text3Primary ? 'text-primary' : 'text-secondary',
          text3Small ? 'heading-secondary' : 'heading-primary'
        ]"
      >&nbsp;{{ text3 }}</span>
    </div>
    <div v-if="readMore" class="typography-header-layout__read-more-link">
      <RouterLink :to="readMoreLink" class="typography-header-layout__read-more-link--link text-narrow">
        Zobacz więcej&nbsp;
      </RouterLink>
      <span class="typography-header-layout__read-more-link--icon">&#9654;&#9654;</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypographyHeader',
  props: {
    text1: {
      type: String,
      required: true,
      default: '',
    },
    text1Primary: {
      type: Boolean,
      required: false,
      default: false,
    },
    text1Small: {
      type: Boolean,
      required: false,
      default: false,
    },
    text2: {
      type: String,
      required: false,
      default: '',
    },
    text2Primary: {
      type: Boolean,
      required: false,
      default: false,
    },
    text2Small: {
      type: Boolean,
      required: false,
      default: false,
    },
    text3: {
      type: String,
      required: false,
      default: '',
    },
    text3Primary: {
      type: Boolean,
      required: false,
      default: false,
    },
    text3Small: {
      type: Boolean,
      required: false,
      default: false,
    },
    readMore: {
      type: Boolean,
      required: false,
      default: false,
    },
    readMoreLink: {
      type: [String, Object],
      required: false,
      default: '/',
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    left: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
