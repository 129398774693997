<template>
  <header class="header-layout--fixed header-layout--fixed-not-safari">
    <div class="header">
      <div class="header__logo">
        <RouterLink to="/">
          <img
            src="/assets/img/logo.svg"
            alt="logo"
            :class="scrollY > 50 ? 'header--fixed-image' : 'header__logo--image'"
          >
        </RouterLink>
      </div>

      <div class="header__menu">
        <NavMenu />
      </div>
    </div>
  </header>
</template>

<script>
import { storeToRefs } from 'pinia/dist/pinia'
import { useSearchStore } from '../../store/search'
import global from '../../mixins/global'
import NavMenu from '@/components/nav/NavMenu.vue'

export default {
  name: 'NavHeader',
  components: { NavMenu },
  mixins: [global],
  setup() {
    const searchStore = useSearchStore()
    const {
      search,
      city,
      radius,
      isInvalid,
      isTouched,
    } = storeToRefs(searchStore)

    return {
      search,
      city,
      radius,
      isInvalid,
      isTouched,
    }
  },
  data() {
    return {
      scrollY: 0,
    }
  },
  computed: {
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    },
  },
  mounted() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        this.scrollY = scrollY
      })
    }
  },
}
</script>
