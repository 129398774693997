<template>
  <section>
    <div class="container">
      <div class="insurances-advertisements">
        <div class="insurances-advertisements__header">
          <slot name="header">
            <CommonTypographyHeader text1="Ubezpieczenia" text2="dla Ciebie" text2-primary />
          </slot>
        </div>

        <div class="insurances-advertisements__container margin-bottom-3">
          <div v-if="productsLimited.length > 0" class="insurances-advertisements__container--cards margin-bottom-3">
            <GoodAgentCard
              v-for="product in productsLimited" :key="product.id" :data="product" type="product"
            />
          </div>

          <div v-if="productsLimited.length === 0">
            <p class="text-invalid text-center">
              Nie znaleziono polecanych produktów.
            </p>
          </div>

          <div v-if="remaining && productsLimited.length > 0" class="insurances-advertisements__container--check-button margin-bottom-3">
            <button class="button button--primary button--big" @click="handleRedirectToGoodAgent">
              <span class="text-bold text-no-decoration">Sprawdź pozostałe ubezpieczenia</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useProductsStore } from '../../store/products'
import links from '../../mixins/links'

export default {
  name: 'GoodAgentNewestAdvertisements',
  mixins: [links],
  props: {
    remaining: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const productsStore = useProductsStore()
    return { productsStore }
  },
  computed: {
    productsLimited() {
      return this.productsStore.getHomeProductsLimitedByNumber(3)
    },
  },
  beforeCreate() {
    this.productsStore.getHomeRecommendedProductsFromAPI();
  },
}
</script>
