<template>
  <InsuranceCategories />
  <GoodAgentNewestAdvertisements class="mb-12" />
  <InsuranceAgencies />
  <BlogNewestAdvertisements />
  <OpinionNewestAdvertisements />
</template>

<script>
import { useBlogStore } from '../store/blog'
import { useBestAgentsStore } from '../store/best-agents'
import { useOpinionsStore } from '../store/opinions'
import { useProductsStore } from '../store/products'

export default {
  name: 'Home',
  setup() {
    const blogStore = useBlogStore()
    const bestAgentsStore = useBestAgentsStore()
    const opinionsStore = useOpinionsStore()
    const productsStore = useProductsStore()

    useHead({
      title: 'Dobry Agent - wyszukiwarka agentów ubezpieczeniowych',
      link: [
        { rel: 'canonical', href: '/', id: 'canonical-homepage' },
      ],
      meta: [
        {
          name: 'robots',
          content: 'index, follow',
        },
        {
          name: 'description',
          content: 'Najlepsza wyszukiwarka agentów ubezpieczeniowych w internecie - powered by ASF Premium! Znajdź najlepszego agenta w TWojej okolicy, lub online. Filtruj po kategoriach lub miastach',
        },
        {
          name: 'keywords',
          content: 'najlepsi, agenci, ubezpieczeniowi, agenci ubezpieczeniowi, asf, asf premium, ubezpieczenia',
        },
      ],
    })

    return { blogStore, bestAgentsStore, opinionsStore, productsStore }
  },
  data() {
    return {
      loading: true,
    }
  },
}
</script>

<route>
{
  name: "glowna",
  meta: {
    layout: 'default'
  }
}
</route>
