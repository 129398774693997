<template>
  <section>
    <div class="container">
      <div class="">
        <CommonTypographyHeader text1="Kategorie " text2="ubezpieczeń" text2-primary />
      </div>
      <div class="categories mb-10">
        <div v-for="category in insurancesLimited" :key="category.name" class="categories__item">
          <div class="categories__item-img-wrapper hover-pointer" @click="handleCategorySearch(category)">
            <img :src="category.images.icon.svg" :alt="category.name" class="categories__item-img">
          </div>

          <span class="text-secondary text-no-decoration hover-pointer categories__item-label" @click="handleCategorySearch(category)">
            {{ category.name }}
          </span>
        </div>
        <button class="categories__item categories__button" @click="showHiddenCategories()">
          Pokaż wszystkie
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { useInsurancesStore } from '../../store/insurances.ts';
import global from '../../mixins/global';
import { useSearchStore } from '../../store/search';

export default {
  name: 'InsuranceCategories',
  mixins: [global],
  setup() {
    const insurancesStore = useInsurancesStore();
    const searchStore = useSearchStore();
    return { insurancesStore, searchStore };
  },
  data() {
    return {};
  },
  computed: {
    insurancesLimited() {
      return this.insurancesStore.getInsurancesLimitedByNumber(4);
    },
  },
  methods: {
    showHiddenCategories() {
      this.$router.push('/kategorie-ubezpieczen');
    },
    handleCategorySearch(category) {
      this.searchStore.setCategoryEmits(true, category)
    },
  },
};
</script>
