<template>
  <nav class="nav">
    <span
      v-if="windowWidth <= lg"
      v-click-outside="toggleMobileFromOutsideMenu"
      class="toggle"
      :class="{ 'toggle--safari' : isSafari }"
      @click="toggleMobile"
    >&#8801;</span>
    <ul class="menu" :class="[{'menu__display' : isMobile }, {'menu__display--fixed' : isMobile && scrollY > 50 }]">
      <li>
        <RouterLink
          :to="{ path:`/agenci-ubezpieczeniowi`, query: {typ_placowki: isOnline ? 'online' : 'stationary'} }"
          class="menu__link"
          :class="{ 'menu__link--active' : isSomeRouteActive('agenci')}"
          @click="toggleMobile();"
        >
          Agenci ubezpieczeniowi
        </RouterLink>
      </li>
      <li>
        <RouterLink
          to="/kategorie-ubezpieczen"
          class="menu__link normal-menu"
          :class="{ 'menu__link--active' : isSomeRouteActive('ubezpieczenia') || isSomeRouteActive('kategorie-ubezpieczen')}"
          @click="toggleMobile"
        >
          Ubezpieczenia
        </RouterLink>
      </li>
      <li>
        <RouterLink
          to="/blog"
          class="menu__link"
          :class="{ 'menu__link--active' : isSomeRouteActive('blog')}"
          @click="toggleMobile"
        >
          Blog
        </RouterLink>
      </li>
      <li>
        <a
          href="https://wspolpraca.dobryagent.pl/"
          class="menu__link"
          :class="{ 'menu__link--active' : isSomeRouteActive('dolacz')}"
          target="_blank"
          @click="toggleMobile"
        >
          Dołącz do nas
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { storeToRefs } from 'pinia/dist/pinia'
import { useInsurancesStore } from '../../store/insurances'
import { useSearchStore } from '../../store/search'
import { useWindowWidth } from '../../composables'
import links from '../../mixins/links'
import global from '../../mixins/global'

export default {
  name: 'NavMenu',
  mixins: [global, links],
  setup() {
    const insurancesStore = useInsurancesStore()
    const searchStore = useSearchStore()
    const {
      search,
      city,
      radius,
      isInvalid,
      isTouched,
      defaultRadius,
    } = storeToRefs(searchStore)
    const { windowWidth, lg } = useWindowWidth()

    return {
      insurancesStore,
      searchStore,
      search,
      city,
      radius,
      isInvalid,
      isTouched,
      defaultRadius,
      windowWidth,
      lg,
    }
  },
  data() {
    return {
      isMobile: false,
      isInsuranceMobile: false,
      scrollY: 0,
    }
  },
  computed: {
    isOnline() {
      return this.searchStore.getSearchOnlineValue
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    },
  },
  mounted() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        this.scrollY = scrollY
      })
    }
  },
  methods: {
    toggleInsurancesMobile() {
      if (this.windowWidth < this.lg) { this.isInsuranceMobile = !this.isInsuranceMobile }
    },
    toggleMobile() {
      if (this.windowWidth < this.lg) { this.isMobile = !this.isMobile }
    },
    toggleMobileFromOutsideMenu($event) {
      if (this.windowWidth < this.lg
          && !$event.target.classList.contains('menu__link')
          && !$event.target.classList.contains('normal-menu')
      ) { this.isMobile = false }
    },
  },
}
</script>
