export const facilityImagePlaceholder = {
  original: '/assets/img/facility-placeholder/facility-placeholder',
  original_png: '/assets/img/facility-placeholder/conversions/original_png.png',
  large_png: '/assets/img/facility-placeholder/conversions/large_png.png',
  medium_png: '/assets/img/facility-placeholder/conversions/medium_png.png',
  small_png: '/assets/img/facility-placeholder/conversions/small_png.png',
  original_webp: '/assets/img/facility-placeholder/conversions/original_webp.webp',
  large_webp: '/assets/img/facility-placeholder/conversions/large_webp.webp',
  medium_webp: '/assets/img/facility-placeholder/conversions/medium_webp.webp',
  small_webp: '/assets/img/facility-placeholder/conversions/small_webp.webp',
}

export const facilityProductImagePlaceholder = {
  original: '/assets/img/facility-placeholder/facility-placeholder',
  original_png: '/assets/img/facility-placeholder/conversions/original_png.png',
  large_png: '/assets/img/facility-placeholder/conversions/large_png.png',
  medium_png: '/assets/img/facility-placeholder/conversions/medium_png.png',
  small_png: '/assets/img/facility-placeholder/conversions/small_png.png',
  original_webp: '/assets/img/facility-placeholder/conversions/original_webp.webp',
  large_webp: '/assets/img/facility-placeholder/conversions/large_webp.webp',
  medium_webp: '/assets/img/facility-placeholder/conversions/medium_webp.webp',
  small_webp: '/assets/img/facility-placeholder/conversions/small_webp.webp',
}
