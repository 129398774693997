<template>
  <div class="good-agent-card" :class="{ 'good-agent-card--leading' : leading }">
    <picture v-if="data.images.image">
      <source
        :srcset="data.images.image.medium_webp"
        type="image/webp"
      >
      <source
        :srcset="data.images.image.medium_png"
        type="image/png"
      >
      <img
        class="good-agent-card__image hover-pointer"
        :src="data.images.image.medium_png"
        :alt="data.extras.image_alt_text"
        :title="data.title"
        @click="handleDetails"
      >
    </picture>

    <img
      src="@/assets/img/icons/good-agent-recommends-icon.svg"
      :alt="data.title"
      :title="data.title"
      class="good-agent-card__category-icon"
    >

    <div class="good-agent-card__title">
      <h3 class="text-secondary-1">
        {{ data.title }}
      </h3>
    </div>

    <div class="good-agent-card__button">
      <button class="button button--secondary button--extra-small" @click="handleDetails">
        <span class="text-extra-small text-bold">Zobacz szczegóły</span>
      </button>
    </div>
  </div>
</template>

<script>
import global from '../../mixins/global'

export default {
  name: 'GoodAgentCard',
  mixins: [global],
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    type: {
      type: String,
      required: true,
      default: 'insurance',
    },
    leading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    handleDetails() {
      this.$router.push(`/dobry-agent-poleca/${this.data.slug}`)
    },
  },
}
</script>
