<script setup lang="ts">
import { storeToRefs } from 'pinia/dist/pinia'
import { useProductsStore } from '~/store/products'
import { useBestAgentsStore } from '~/store/best-agents'
import { useBlogStore } from '~/store/blog'
import { useOpinionsStore } from '~/store/opinions'
import { useInsurancesStore } from '~/store/insurances'
import { useSearchStore } from '~/store/search'

useHead({
  title: 'Dobry Agent - wyszukiwarka agentów ubezpieczeniowych',
  meta: [
    {
      name: 'robots',
      content: 'index, follow',
    },
    {
      name: 'description',
      content: 'Najlepsza wyszukiwarka agentów ubezpieczeniowych w internecie - powered by ASF Premium! Znajdź najlepszego agenta w TWojej okolicy, lub online. Filtruj po kategoriach lub miastach',
    },
    {
      name: 'keywords',
      content: 'najlepsi, agenci, ubezpieczeniowi, agenci ubezpieczeniowi, asf, asf premium, ubezpieczenia',
    },
  ],
})

const router = useRouter()
const loading = ref(true)

router.beforeEach((to, from, next) => {
  const insurancesStore = useInsurancesStore()
  const productsStore = useProductsStore()
  const bestAgentsStore = useBestAgentsStore()
  const blogStore = useBlogStore()
  const opinionsStore = useOpinionsStore()
  const searchStore = useSearchStore()

  Promise
    .all([
      searchStore.getDefaultCities(),
      searchStore.getSearchPlaceholders(),
      insurancesStore.getInsurancesFromAPI(),
      productsStore.getRecommendedProductsFromAPI(),
      bestAgentsStore.getBestAgentsFromAPI(),
      blogStore.getHomepageArticlesFromAPI(),
      opinionsStore.getRandomOpinionsFromAPI(),
    ])
    .then((values) => {
      loading.value = false
      next()
    })
})
</script>

<template>
  <CommonSiteLoading v-if="loading" />
  <router-view v-else v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
  <CommonCookieModal v-if="!loading" />
</template>

<style lang="scss">
@import "./assets/sass/main.scss";
</style>
