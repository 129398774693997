<template>
  <section>
    <div class="container">
      <div
        :class="searchHome || searchCategory
          ? 'search-layout'
          : 'search-layout search-layout--small'"
      >
        <div
          :class="searchHome || searchCategory
            ? 'search-layout__container'
            : 'search-layout__container search-layout__container--small'"
        >
          <div class="search">
            <div v-if="searchCategory" class="search__header-category">
              <img :src="categoryData.images.icon.svg" :alt="categoryData.name" class="search__header--image">
              <div class="search__header--content">
                <h1 class="heading-secondary text-bold text-secondary">
                  Ubezpieczenia {{ categoryData.name }}
                </h1>
              </div>
            </div>
            <div v-if="searchProduct && productData" class="search__header-product">
              <h1>
                <span class="text-bold">
                  {{ productData.title }}
                </span>
              </h1>
              <span class="text-secondary text-bold">
                <span
                  v-for="(category, index) in productData.insurance_categories"
                  :key="category"
                  class="text-primary"
                >
                  {{ category.name }}{{ index + 1 < productData.insurance_categories.length ? ', ' : '' }}
                </span>
                <span v-if="productData.insurance_companies.length >= 1" class="text-primary">, </span>
                <span
                  v-for="(company, index) in productData.insurance_companies"
                  :key="company"
                  class="text-primary"
                >
                  {{ company.name }}{{ index + 1 < productData.insurance_companies.length ? ', ' : '' }}
                </span>
              </span>
            </div>
            <div v-if="!searchCategory && !searchProduct" class="search__header-text">
              <h1>
                <span class="text-primary text-bold">
                  Znajdź
                </span> <span class="text-secondary text-bold">
                  Dobrego <span class="text-primary">Agenta</span> i wybierz najlepsze ubezpieczenie
                </span>
              </h1>
            </div>

            <div v-if="searchHome || searchCategory || searchResults || searchAgents || searchProduct" class="search__online">
              <label
                for="radio1"
                :class="isOnline ? 'button--search-online-active' : 'button--search-online-not-active'"
                class="button--search-online button--extra-small"
              >Stacjonarnie</label>

              <input
                id="radio1" v-model="isOnline" :value="false" name="stacjonarnie" type="radio"
                class="radio-search-online"
              >

              <label
                for="radio2"
                :class="!isOnline ? 'button--search-online-active' : 'button--search-online-not-active'"
                class="button--search-online button--extra-small"
              >Online</label>

              <input
                id="radio2" v-model="isOnline" :value="true" name="online" type="radio"
                class="radio-search-online"
              >
            </div>

            <div class="search__form">
              <SearchForm
                :is-search="searchHome || searchAgents || searchResults"
                :is-city="searchLocalisation || !isOnline"
                :is-radius="searchLocalisation || (searchCategory && !isOnline) || (searchAgents && !isOnline) || (searchResults && !isOnline) || (searchProduct && !isOnline)"
                :is-online="isOnline"
                :class="{
                  'search-form__online': isOnline,
                  'search-form__online--category': (searchCategory && isOnline) || (searchProduct && isOnline),
                  'search-form__agents-results': (searchAgents && !isOnline) || (searchResults && !isOnline),
                  'search-form__home': searchHome && !isOnline,
                  'search-form__category': (searchCategory && !isOnline) || (searchProduct && !isOnline) || searchLocalisation,
                }"
                @validation="handleValidation($event)"
                @params-changed="handleNewValues($event)"
              />
            </div>

            <div class="search__validation">
              <span
                v-if="isInvalid.search"
                class="text-extra-small text-invalid text-narrow"
              >Wybierz kategorię lub wpisz własną frazę.
              </span>
              <span v-if="!isOnline && isInvalid.city" class="text-extra-small text-invalid text-narrow">
                Wybierz miasto.
              </span>
              <span v-if="!isOnline && isInvalid.radius" class="text-extra-small text-invalid text-narrow">
                Wybierz odległość.
              </span>
            </div>

            <div v-if="searchHome" class="search__button">
              <button
                class="button button--secondary button--search-agents"
                @click="handleRedirectToLocalisationSearch"
              >
                <span>Znajdź wszystkich Agentów w swojej okolicy</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia/dist/pinia'
import { useSearchStore } from '../../store/search'
import SearchForm from '@/components/search/Form.vue'
import global from '@/mixins/global'

export default {
  name: 'SearchWrapper',
  components: { SearchForm },
  mixins: [global],
  props: {
    searchHome: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchAgents: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchCategory: {
      type: Boolean,
      required: false,
      default: false,
    },
    categoryData: {
      type: Object,
      required: false,
      default: () => {},
    },
    searchLocalisation: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchResults: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchProduct: {
      type: Boolean,
      required: false,
      default: false,
    },
    productData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup() {
    const searchStore = useSearchStore()
    const {
      categorySearchEmits,
      productSearchEmits,
      search,
      city,
      radius,
      defaultCity,
      defaultRadius,
      isOnline,
      isInvalid,
    } = storeToRefs(searchStore)

    return {
      searchStore,
      categorySearchEmits,
      productSearchEmits,
      search,
      city,
      radius,
      defaultCity,
      defaultRadius,
      isOnline,
      isInvalid,
    }
  },
  watch: {
    categorySearchEmits: {
      immediate: true,
      deep: true,
      handler(nV, oV) {
        if (nV && nV.emit === true) {
          this.$router.push({
            path: nV.value.slug,
            query: {
              szukaj_wszedzie: 1,
              kategoria: nV.value.category_slug,
              typ_placowki: this.isOnline ? 'online' : 'stationary',
            },
          })
          this.searchStore.setCategoryEmits(false, {})
        }
      },
    },
    productSearchEmits: {
      immediate: true,
      deep: true,
      handler(nV, oV) {
        if (nV && nV.emit === true) {
          console.log('product search push')
          this.$router.push({
            path: '/wyszukiwarka-produktu',
            query: {
              szukaj_wszedzie: 1,
              produkt: nV.value.slug,
              typ_placowki: this.isOnline ? 'online' : 'stationary',
            },
          })
          this.searchStore.setProductEmits(false, {})
        }
      },
    },
  },
  methods: {
    handleOnline(choose) {
      this.isOnline = choose
    },
    handleNewValue(type) {
      switch (type) {
        case 'city':
          this.city = this.defaultCity
          return this.city.id
        case 'radius':
          this.radius = this.defaultRadius
          return this.radius.radius
      }
    },
    handleRedirectToLocalisationSearch() {
      this.$router.push({
        path: '/wyszukiwarka-lokalizacyjna',
        query: { typ_placowki: 'stationary' },
      })
    },
    handleValidation(validation) {
      this.isInvalid[validation.type] = validation.isInvalid
    },
    handleNewValues(values) {
      switch (values.param) {
        case 'search':
          this.search = values.value
          break
        case 'city':
          this.city = values.value
          break
        case 'radius':
          this.radius = values.value
      }
    },
  },
}
</script>
