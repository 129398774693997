<template>
  <div class="fixed-header-padding">
    <HeaderWrapper />
    <SearchWrapper
      v-show="showSearchForm"
      :search-agents="isRouteStartsWith('/agenci-ubezpieczeniowi') || isRouteStartsWith('/kategorie-ubezpieczen')"
      :search-home="isRouteStartsWith('/home')"
      :search-category="isRouteStartsWith('/ubezpieczenia')"
      :search-localisation="isRouteStartsWith('/wyszukiwarka-lokalizacyjna')"
      :search-results="isRouteStartsWith('/szukaj')"
      :search-product="isRouteStartsWith('/wyszukiwarka-produktu')"
      :category-data="categoryData"
      :product-data="productData"
    />
    <router-view v-bind="$attrs" />
    <CommonFooter />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useInsurancesStore } from '../store/insurances'
import { useSearchStore } from '../store/search'
import { useBlogStore } from '../store/blog'
import links from '../mixins/links'
import { useProductsStore } from '../store/products'
import { useCommonStore } from '../store/common'

export default defineComponent({
  name: 'DefaultLayout',
  mixins: [links],
  scrollToTop: true,
  inheritAttrs: false,
  setup() {
    const insurancesStore = useInsurancesStore()
    const searchStore = useSearchStore()
    const blogStore = useBlogStore()
    const productsStore = useProductsStore()
    const commonStore = useCommonStore()
    return { insurancesStore, searchStore, blogStore, productsStore, commonStore }
  },
  data() {
    return {}
  },
  computed: {
    showSearchForm() {
      return (
        // this.isRouteStartsWith('/home')     ||
        // this.isRouteStartsWith('/agenci-ubezpieczeniowi')      ||
        // this.isRouteStartsWith('/ubezpieczenia')      ||
        this.isRouteStartsWith('/szukaj')
        || this.isRouteStartsWith('/wyszukiwarka-lokalizacyjna')
        // || this.isRouteStartsWith('/wyszukiwarka-produktu')
      )
    },
    categories() {
      return this.insurancesStore.getInsurances
    },
    categoryData() {
      return this.categories.find(value => value.slug.includes(this.$route.path))
    },
    products() {
      return this.productsStore.getProducts
    },
    productData() {
      return this.productsStore.getSingleProduct
    },
  },
  watch: {
    $route(to, from) {
      if (Object.keys(to.query).length === 0 && from.name !== to.name) {
        this.searchStore.resetSearch();
      }
    },
  },
  created() {
    // this.searchStore.getSearchPlaceholders()
    this.searchStore.getDefaultCities()
    this.commonStore.getPrivacyPolicyFromAPI()
    this.commonStore.getRegulationsFromAPI()
    this.commonStore.getRodoFromAPI()
  },
  mounted() { },
})
</script>
