import { radiusDefault } from '../store/search-default-data'

export default {
  methods: {
    async getSearchResultsFromAPI() {
      await this.searchStore.getSearchResults({
        search: this.$route.query.szukaj,
        facility_type: this.$route.query.typ_placowki || 'stationary',
        radius: this.$route.query.odleglosc,
        ...(this.$route.query.typ_placowki === 'stationary' && { city: this.$route.query.miasto }),
      })
    },
    async getMoreSearchResultsFromAPI(page) {
      this.loadingMore = true
      await this.searchStore.getMoreSearchResultsByPage({
        search: this.$route.query.szukaj,
        facility_type: this.$route.query.typ_placowki,
        radius: this.$route.query.odleglosc,
        ...(this.$route.query.typ_placowki === 'stationary' && { city: this.$route.query.miasto }),
        page,
      }).then(() => this.loadingMore = false)
    },

    async getLocalisationSearchResultsFromAPI() {
      await this.searchStore.getLocalisationSearchResults({
        city: this.$route.query.miasto,
        radius: this.$route.query.odleglosc,
      })
    },

    async getMoreLocalisationSearchResultsFromAPI(page) {
      this.loadingMore = true
      await this.searchStore.getMoreLocalisationSearchResultsByPage({
        city: this.$route.query.miasto,
        radius: this.$route.query.odleglosc,
        page,
      }).then(() => this.loadingMore = false)
    },

    async getAllFacilitiesSearchResultsFromAPI(facility_type) {
      await this.searchStore.getAllFacilitiesSearchResults({
        facility_type,
      })
    },

    async getMoreAllFacilitiesResultsFromAPI(page) {
      this.loadingMore = true
      await this.searchStore
        .getMoreAllFacilitiesSearchResultsByPage({
          facility_type: this.$route.query.typ_placowki,
          page,
        })
        .then(() => this.loadingMore = false)
    },

    async getMoreArticlesByCategorySlugByPageFromAPI(page) {
      this.loadingMore = true
      await this.searchStore.getMoreArticlesByCategorySlugByPage({
        slug: this.$route.params.category,
        page,
      }).then(() => this.loadingMore = false)
    },

    async getCategorySearchResultsFromAPI() {
      await this.searchStore.getCategorySearchResults({
        empty_search: this.$route.query.szukaj_wszedzie,
        category_slug: this.$route.query.kategoria,
        facility_type: this.$route.query.typ_placowki,
        ...(this.$route.query.typ_placowki === 'stationary' && {
          city: this.$route.query.miasto,
          radius: this.$route.query.odleglosc,
        }),
      })
    },
    async getCategoryMoreSearchResultsFromAPI(page) {
      this.loadingMore = true
      await this.searchStore.getMoreCategorySearchResultsByPage({
        empty_search: this.$route.query.szukaj_wszedzie,
        category_slug: this.$route.query.kategoria,
        facility_type: this.$route.query.typ_placowki,
        ...(this.$route.query.typ_placowki === 'stationary' && {
          city: this.$route.query.miasto,
          radius: this.$route.query.odleglosc,
        }),
        page,
      }).then(() => this.loadingMore = false)
    },

    async getProductSearchResultsFromAPI() {
      await this.searchStore.getProductSearchResults({
        empty_search: this.$route.query.szukaj_wszedzie,
        product_slug: this.$route.query.produkt,
        facility_type: this.$route.query.typ_placowki,
        ...(this.$route.query.typ_placowki === 'stationary' && {
          city: this.$route.query.miasto,
          radius: this.$route.query.odleglosc,
        }),
      })
    },
    async getProductMoreSearchResultsFromAPI(page) {
      this.loadingMore = true
      await this.searchStore.getMoreProductSearchResultsByPage({
        empty_search: this.$route.query.szukaj_wszedzie,
        product_slug: this.$route.query.produkt,
        facility_type: this.$route.query.typ_placowki,
        ...(this.$route.query.typ_placowki === 'stationary' && {
          city: this.$route.query.miasto,
          radius: this.$route.query.odleglosc,
        }),
        page,
      }).then(() => this.loadingMore = false)
    },
    load($state) {
      try {
        if (this.page < this.resultsPages.lastPage
          && this.resultsPages.lastPage > 1) {
          this.page++
          if (this.$route.path.includes('/wyszukiwarka-lokalizacyjna') && !this.$route.query.miasto && !this.$route.query.radius) {
            this.getMoreAllFacilitiesResultsFromAPI(this.page).then(() => $state.loaded())
          } else if (this.$route.path.includes('/wyszukiwarka-lokalizacyjna') && this.$route.query.miasto && this.$route.query.odleglosc) {
            this.getMoreLocalisationSearchResultsFromAPI(this.page).then(() => $state.loaded())
          } else if (this.$route.path.includes('/agenci-ubezpieczeniowi')) {
            this.getMoreAllFacilitiesResultsFromAPI(this.page).then(() => $state.loaded())
          } else if (this.$route.path.includes('/ubezpieczenia')) {
            console.log('infinite-loader-category')
            this.getCategoryMoreSearchResultsFromAPI(this.page).then(() => $state.loaded())
          } else if (this.$route.path.includes('/wyszukiwarka-produktu') && !this.$route.query.miasto && !this.$route.query.radius) {
            this.getProductMoreSearchResultsFromAPI(this.page).then(() => $state.loaded())
          } else {
            this.getMoreSearchResultsFromAPI(this.page).then(() => $state.loaded())
          }
        } else {
          $state.complete()
        }
      } catch (error) {
        $state.error()
      }
    },
    loadArticles($state, type) {
      try {
        if (type === 'category' && this.page < this.categoryPages.lastPage
          && this.categoryPages.lastPage > 1) {
          this.page++
          this.loadingMore = true
          this.blogStore.getMoreArticlesByCategorySlugByPage({
            slug: this.$route.params.category,
            page: this.page,
          }).then(() => {
            $state.loaded()
            this.loadingMore = false
          })
        } else if (type === 'index' && this.page < this.indexPages.lastPage
          && this.indexPages.lastPage > 1) {
          this.page++
          this.loadingMore = true
          this.blogStore.getMoreArticlesByPage({
            page: this.page,
          }).then(() => {
            $state.loaded()
            this.loadingMore = false
          })
        } else if (type === 'tag' && this.page < this.tagPages.lastPage
          && this.tagPages.lastPage > 1) {
          this.page++
          this.loadingMore = true
          this.blogStore.getMoreArticlesByTagIdByPage({
            tag_id: this.$route.params.tagId,
            page: this.page,
          }).then(() => {
            $state.loaded()
            this.loadingMore = false
          })
        } else {
          $state.complete()
        }
      } catch (error) {
        $state.error()
      }
    },

    getImageByPaymentName(name) {
      let imageName = ''
      switch (name) {
        case 'Przelew':
          imageName = 'payment-bank-transfer'
          break
        case 'Karta płatnicza':
          imageName = 'payment-credit-card'
          break
        case 'Gotówka':
          imageName = 'payment-cash'
          break
        case 'Blik':
          imageName = 'payment-blik'
          break
      }
      return imageName
    },

    getImageUrl(type, name) {
      return `/assets/img/${type}/${name}.png`
    },
    getImageUrlSvg(type, name) {
      return `/assets/img/${type}/${name}.svg`
    },
    getImageUrlWebp(type, name) {
      return `/assets/img/${type}/${name}.webp`
    },
    compareProperty(a, b, property) {
      const property1 = a[property].toUpperCase()
      const property2 = b[property].toUpperCase()

      let comparison = 0

      if (property1 > property2) {
        comparison = 1
      } else if (property1 < property2) {
        comparison = -1
      }

      return comparison
    },
    sortBy(option) {
      if (option.method === 'rating') {
        this.results.sort((a, b) => b[option.method] - a[option.method])
      } else if (option.method === 'city') {
        this.results.sort((a, b) => {
          if (b.city.name < a.city.name) {
            return 1
          }
          if (b.city.name > a.city.name) {
            return -1
          }
          return 0
        })
      } else {
        this.results.sort((a, b) => this.compareProperty(a, b, option.method))
      }
    },
    unSlugify(slug) {
      const slug2 = slug.replaceAll(('-', ' '))
      const arr = slug2.split(' ')
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
      }

      return arr.join(' ')
    },
    slugify(title) {
      let slug = ''
      // Change to lower case
      const titleLower = title.toLowerCase()
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd')
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '')
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-')

      return slug
    },

    resetSearch() {
      console.log('before reset?')
      this.search = null
      this.city = null
      this.radius = radiusDefault
      this.isOnline = false
      this.isTouched = {
        search: false,
        city: false,
        radius: false,
      }
      this.isInvalid = {
        search: false,
        city: false,
        radius: false,
      }
      console.log('after reset?')
    },

    handleRedirectToArticle(data) {
      this.$router.push(`/blog/${data.category.slug}/${data.id}/${data.slug}`)
    },
  },
}
