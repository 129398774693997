import { acceptHMRUpdate, defineStore } from 'pinia'
import axios from 'axios'

export interface InsuranceCategory {
  slug: string
  type: string
  name: string
  description: string
  images: {
    icon: {
      svg: string
    }
  }
}

export interface InsuranceCategoryState {
  insurances: InsuranceCategory[]
  insurancesDownloaded: boolean
}

export const useInsurancesStore = defineStore('insurances', {
  state(): InsuranceCategoryState {
    return {
      insurances: [],
      insurancesDownloaded: false,
    }
  },
  actions: {
    async getInsurancesFromAPI() {
      if (this.insurancesDownloaded) {
        return
      }

      const results = await axios.get(`${import.meta.env.VITE_API_URL}/v1/homepage/insurances`)

      results.data.data.forEach((v, i) => {
        v.category_slug = v.slug
        v.slug = `/ubezpieczenia-${v.slug}`

        // seo data placeholders - when they're not defined by backend
        v.seo_slug = v.seo_slug ? v.seo_slug : v.slug
        v.seo_title = v.seo_title ? v.seo_title : 'Kategoria ubezpieczeń'
        v.seo_description = v.seo_description ? v.seo_description : 'Dobry Agent - wyszukiwarka najlepszych agentów ubezpieczeniowych po kategorii ubezpieczeń (z filtracją po lokalizacji lub online)'
        v.seo_meta_keywords = v.seo_meta_keywords ? v.seo_meta_keywords : 'dobry agent, kategorie ubezpieczeń, wyszukiwarka, agenci ubezpieczeniowi, ubezpieczenia, komunikacyjne, turystyczne, na życie, zdrowotne, szkolne, korporacyjne, firmowe'
      })
      this.insurances = results.data.data
      this.insurancesDownloaded = true
    },
  },
  getters: {
    getInsurances: (state: InsuranceCategoryState) => state.insurances && state.insurances,
    getInsurancesLimitedByNumber: (state: InsuranceCategoryState) => (number: number) => state.insurances && state.insurances.slice(0, number),
    getCategoryDataBySlug: (state: InsuranceCategoryState) => slug => state.insurances && state.insurances.find(value => value.slug === slug),
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInsurancesStore, import.meta.hot))
}
