import axios from 'axios'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { facilityImagePlaceholder } from '~/store/facility-default-data'

export interface BestAgentsState {
  bestAgents: object[]
  bestAgentsDownloaded: boolean
}

export const useBestAgentsStore = defineStore('best-agents', {
  state(): BestAgentsState {
    return {
      bestAgents: Array(6).fill({}),
      bestAgentsDownloaded: false,
    }
  },
  actions: {
    async getBestAgentsFromAPI() {
      if (this.bestAgentsDownloaded) {
        return;
      }
      const res = await axios.get(`${import.meta.env.VITE_API_URL}/v1/homepage/facilities`)

      if (res.data.data.length > 0) {
        res.data.data.forEach((v) => {
          v.rating = parseFloat(v.opinions_avg)
          v.imagesArray = Object.values(v.images)
          v.imagesPlaceholderArray = [facilityImagePlaceholder]
        })
      }

      this.bestAgents = res.data.data.length > 0 ? res.data.data : 'not-found'
      this.bestAgentsDownloaded = true
    },

  },
  getters: {
    getBestAgents: state => state.bestAgents,
    getBestAgentsLimited: state => (number: number) => state.bestAgents && state.bestAgents.slice(0, number),
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBestAgentsStore, import.meta.hot))
}
