<template>
  <div class="search-form">
    <multiselect
      v-if="isSearch"
      :key="reloadSearch"
      ref="multiselect-search"
      v-model="search"
      class="search-form__search search-form__select"
      :class="{'search-form__select--invalid' : isInvalid('search')}"
      track-by="name"
      label="name"
      placeholder="Co chcesz ubezpieczyć?"
      :options="optionsSearch"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      group-values="subcategories"
      group-label="name"
      :group-select="false"
      :allow-empty="true"
      :internal-search="false"
      :loading="searchLoading"
      :show-labels="true"
      select-label=""
      deselect-label="&#x2716;"
      selected-label="&#x2714;"
      @search-change="onSearch($event, 'search')"
      @select="onSelect($event, 'search')"
      @change="onChange($event, 'search')"
      @close="onTouch('search')"
      @open="resetSavedSearchOption"
    >
      <template #singleLabel="{ option }">
        <div class="search-form__select-options-grid">
          <span class="search-form__multiselect-selected search-form__select-options-grid--name">
            {{ option && option.name }}</span>
        </div>
      </template>
      <template #option="{ option }">
        <div
          v-if="option && option.$isLabel"
          class="search-form__multiselect-option search-form__multiselect-option--group"
        >
          {{ option.$groupLabel }}
        </div>
        <div class="search-form__multiselect-option" @click="handleOptionClick(option)">
          {{ option && option.name }} {{ option && option.surname }}
        </div>
      </template>
      <template #noResult>
        <div id="category-no-results" class="search-form__multiselect-option search-form__multiselect-option--no-result">
          Brak wyników
        </div>
      </template>
    </multiselect>

    <multiselect
      v-if="isCity"
      ref="multiselect-city"
      v-model="city"
      track-by="id"
      label="name"
      class="search-form__city search-form__select search-form__select--city"
      :class="[
        {'search-form__select--invalid' : isInvalid('city')},
        { 'search-form__multiselect-no-transform' : !city }
      ]"
      placeholder="Miejscowość?"
      :options="optionsCity"
      :searchable="true"
      :multiple="false"
      :close-on-select="true"
      :allow-empty="true"
      :internal-search="false"
      :loading="cityLoading"
      :show-labels="true"
      select-label=""
      deselect-label="&#x2716;"
      selected-label="&#x2714;"
      @search-change="onSearch($event, 'city')"
      @select="onSelect($event, 'city')"
      @close="onTouch('city')"
    >
      <template #singleLabel="{ option }">
        <span class="search-form__multiselect-selected">{{ option.name }}</span>
      </template>

      <template #option="{ option }">
        <div class="search-form__select--option">
          <span class="text-small">{{ option.name }},</span>
          <span class="text-extra-small">{{ option.voivodeship }}</span>
        </div>
      </template>

      <template #noOptions>
        <span id="city-no-options">Wprowadź miasto</span>
      </template>

      <template #noResult>
        <span id="city-no-results">Brak wyników</span>
      </template>
    </multiselect>

    <multiselect
      v-if="isRadius"
      ref="multiselect-radius"
      v-model="radius"
      deselect-label="Can't remove this value"
      class="search-form__radius search-form__select"
      :class="{'search-form__select--invalid' : isInvalid('radius')}"
      track-by="radius"
      label="radius"
      placeholder="Odległość?"
      :options="optionsRadius"
      :searchable="false"
      :multiple="false"
      :allow-empty="false"
      :show-labels="false"
      @close="onTouch('radius')"
    >
      <template #option="{ option }">
        <div class="search-form__multiselect-option text-small">
          + {{ option.radius }} km
        </div>
      </template>

      <template #singleLabel="{ option }">
        <span class="search-form__multiselect-selected">{{ option.name }}</span>
      </template>
    </multiselect>

    <button
      type="button" class="button button--primary button--search-form"
      :class="{'search__button' : !isSearch && !isCity && !isRadius}" @click="onSubmit"
    >
      Szukaj
    </button>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { storeToRefs } from 'pinia/dist/pinia'
import { useSearchStore } from '../../store/search'
import global from '../../mixins/global'

export default {
  name: 'SearchForm',
  mixins: [global],
  props: {
    isSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    isCity: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRadius: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOnline: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['validation', 'paramsChanged'],
  setup() {
    const config = { apiUrl: import.meta.env.VITE_API_URL }
    const searchStore = useSearchStore()
    const {
      search,
      city,
      radius,
      isTouched,
      defaultSearchOptions,
      defaultCityOptions,
      defaultRadiusOptions,
    } = storeToRefs(searchStore)

    return {
      config,
      searchStore,
      search,
      city,
      radius,
      isTouched,
      defaultSearchOptions,
      defaultCityOptions,
      defaultRadiusOptions,
    }
  },
  data() {
    return {
      savedSearchOption: null,
      reloadSearch: false,
      optionsSearch: this.defaultSearchOptions,
      optionsCity: this.defaultCityOptions,
      optionsRadius: this.defaultRadiusOptions,
      searchLoading: false,
      cityLoading: false,
    }
  },
  watch: {
    '$route.path': {
      handler(nV, oV) {
        if (nV.includes('/wyszukiwarka-lokalizacyjna')) {
          this.resetValidation()
        }
      },
    },
    search(nV, oV) {
      if (nV === null && this.$refs['multiselect-search']) {
        this.$refs['multiselect-search'].search = '%27%27'
        this.reloadSearch = !this.reloadSearch
      }
      if (nV && this.savedSearchOption && this.savedSearchOption.name && nV.name !== this.savedSearchOption.name) {
        this.$refs['multiselect-search'].select(this.savedSearchOption, 1)
        this.$refs['multiselect-search'].search = this.savedSearchOption.name
        this.resetSavedSearchOption()
      }
    },
  },
  methods: {
    resetSavedSearchOption() {
      this.savedSearchOption = null
    },
    resetSearch() {
      this.search = null
    },
    popstateEventAction() {
      if (this.$refs['multiselect-search']
        && this.search
        && this.search.name
        && this.search.type === 'user_input') {
        this.$refs['multiselect-search'].search = this.search.name
      }
    },
    async autocomplete(query, type) {
      if (type === 'search') {
        if (query.trim().length === 0) {
          this.optionsSearch = this.searchStore.getDefaultSearchOptions;
          return
        }

        this.searchLoading = true
        const res = await axios.get(`${this.config.apiUrl}/v1/search-tips?searchString=${query}`)
        this.optionsSearch = res.data.data
        this.searchLoading = false
      }
      if (type === 'city') {
        this.cityLoading = true
        const res = await axios.get(`${this.config.apiUrl}/v1/cities?city=${query}`)
        this.optionsCity = res.data.data
        this.cityLoading = false
      }
    },
    onChange(value, type) {
      if (type === 'search' && this.$refs['multiselect-search'].search) {
        this.$refs['multiselect-search'].select({
          name: this.$refs['multiselect-search'].search,
          type: 'user_input',
        }, 1)
      } else if (type === 'search' && !this.$refs['multiselect-search'].search) {
        this.$refs['multiselect-search'].select(null)
      }

      if (type === 'city' && value) {
        this.city = value.id
      }

      if (type === 'radius' && value) {
        this.radius = value.radius
      }
    },
    onSearch(query, type) {
      if ((type === 'city' && query.length >= 3)
        || (type === 'search')) {
        this.autocomplete(query, type)
      }
    },
    onSelect(option, type) {
      if (type === 'search' && this.isCity && !this.city) {
        this.$refs['multiselect-city'].activate()
      }
      if (type === 'city' && this.isSearch && !this.search) {
        this.$refs['multiselect-search'].activate()
      }
    },
    onTouch(type) {
      if (this[type]) {
        this.isTouched[type] = true
      }
    },
    isInvalid(type) {
      if (this.$route.path.includes('/agenci-ubezpieczeniowi')
        || this.$route.path.includes('/szukaj')
        || this.$route.path === ('/')
      ) {
        const isInvalid = (this.isTouched[type] && !this[type])
        this.$emit('validation', { type, isInvalid })
        return isInvalid
      }
    },
    resetValidation() {
      this.isTouched = {
        search: false,
        city: false,
        radius: false,
      }
      this.$emit('validation', { type: 'search', isInvalid: false })
      this.$emit('validation', { type: 'city', isInvalid: false })
    },
    async onSubmit() {
      // SEARCH BY CATEGORY
      if (this.$route.path.startsWith('/ubezpieczenia')) {
        if (!this.isOnline && this.city && this.radius) {
          await this.getSearchResults('category-city')
        } else if (this.isOnline) {
          await this.getSearchResults('category-all')
        } else if (!this.isOnline && !this.city) {
          await this.getSearchResults('category-all')
        }
      }

      // SEARCH BY RECOMMENDED PRODUCT (GOOD-AGENT-RECOMMENDS)
      if (this.$route.path.includes('/wyszukiwarka-produktu')) {
        if (!this.isOnline && this.city && this.radius) {
          console.log('search product stationary')
          await this.getSearchResults('product-city')
        } else if (this.isOnline) {
          console.log('search product online')
          await this.getSearchResults('product-all')
        } else if (!this.isOnline && !this.city) {
          await this.getSearchResults('product-all')
        }
      }

      // LOCALISATION SEARCH
      if (this.$route.path.includes('/wyszukiwarka-lokalizacyjna')) {
        if (!this.city && this.radius) {
          return this.$router.push({
            name: 'wyszukiwarka-lokalizacyjna',
            query: {
              typ_placowki: 'stationary',
            },
          })
        }
        if (this.city && this.radius) {
          return this.$router.push({
            name: 'wyszukiwarka-lokalizacyjna',
            query: {
              miasto: this.city.id,
              odleglosc: this.radius.radius,
            },
          })
        }
      }

      // FACILITIES SEARCH
      if (this.$route.path.includes('/agenci-ubezpieczeniowi')) {
        if (!this.isOnline && this.search && this.city && this.radius) {
          await this.getSearchResults('normal')
        } else if ((this.isOnline && !this.search && !this.isInvalid('search')) || (!this.isOnline && !this.search && !this.city && !this.isInvalid('search') && !this.isInvalid('city'))) {
          await this.getSearchResults('facilities')
        } else if (this.isOnline && this.search) {
          await this.getSearchResults('normal')
        } else if (!this.isOnline && !this.search && this.city) {
          this.$emit('validation', { type: 'search', isInvalid: true })
        } else if (!this.isOnline && !this.city && this.search) {
          this.$emit('validation', { type: 'city', isInvalid: true })
        }
      }

      // RESULTS SEARCH
      if (this.$route.path.includes('/szukaj')) {
        if (!this.isOnline && this.search && this.city && this.radius) {
          await this.getSearchResults('normal')
        } else if ((this.isOnline && !this.search) || (!this.isOnline && !this.search && !this.city)) {
          this.$emit('validation', { type: 'search', isInvalid: true })
          this.$emit('validation', { type: 'city', isInvalid: true })
        } else if (this.isOnline && this.search) {
          await this.getSearchResults('normal')
        } else if (!this.isOnline && !this.search && this.city) {
          this.$emit('validation', { type: 'search', isInvalid: true })
        } else if (!this.isOnline && !this.city && this.search) {
          this.$emit('validation', { type: 'city', isInvalid: true })
        }
      }

      // HOMEPAGE SEARCH
      if (this.$route.path === '/') {
        if (!this.isOnline && this.search && this.city && this.radius) {
          await this.getSearchResults('normal')
        } else if ((this.isOnline && !this.search && !this.isInvalid('search')) || (!this.isOnline && !this.search && !this.city && !this.isInvalid('search') && !this.isInvalid('city'))) {
          await this.getSearchResults('facilities')
        } else if (this.isOnline && this.search) {
          await this.getSearchResults('normal')
        } else if (!this.isOnline && !this.search && this.city) {
          this.$emit('validation', { type: 'search', isInvalid: true })
        } else if (!this.isOnline && !this.city && this.search) {
          this.$emit('validation', { type: 'city', isInvalid: true })
        }
      }
    },
    getSearchResults(searchType) {
      const search = this.search && this.search.name
      const city = this.city && this.city.id
      const radius = this.radius && this.radius.radius
      const facilityType = this.isOnline ? 'online' : 'stationary'

      const category_slug = this.$route.query && this.$route.query.kategoria && this.$route.query.kategoria
      const product_slug = this.$route.query && this.$route.query.produkt && this.$route.query.produkt
      this.searchStore.resetResults()

      // SEARCH BY CATEGORY
      if (searchType === 'category-city') {
        if (facilityType === 'stationary') {
          this.$router.push({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              typ_placowki: facilityType,
              szukaj_wszedzie: 0,
              miasto: city,
              odleglosc: radius,
            },
          }).then(() => {
            this.searchStore.getCategorySearchResults({
              empty_search: this.$route.query.szukaj_wszedzie,
              category_slug: this.$route.query.kategoria,
              facility_type: facilityType,
              city,
              radius,
            })
          })
        }
      }
      if (searchType === 'category-all') {
        if (facilityType === 'stationary') {
          this.$router.push({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              szukaj_wszedzie: 1,
              typ_placowki: facilityType,
            },
          }).then(() => {
            this.searchStore.getCategorySearchResults({
              empty_search: this.$route.query.szukaj_wszedzie,
              category_slug: this.$route.query.kategoria,
              facility_type: facilityType,
              city,
              radius,
            })
          })
        } else if (facilityType === 'online') {
          this.$router.push({
            path: this.$route.path,
            query: {
              szukaj_wszedzie: 1,
              kategoria: category_slug,
              typ_placowki: facilityType,
            },
          }).then(() => {
            this.searchStore.getCategorySearchResults({
              empty_search: this.$route.query.szukaj_wszedzie,
              category_slug: this.$route.query.kategoria,
              facility_type: facilityType,
            })
          })
        }
      } else if (searchType === 'product-city') {
        // SEARCH BY PRODUCT
        if (facilityType === 'stationary') {
          this.$router.push({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              typ_placowki: facilityType,
              szukaj_wszedzie: 0,
              miasto: city,
              odleglosc: radius,
            },
          }).then(() => {
            this.searchStore.getProductSearchResults({
              empty_search: this.$route.query.szukaj_wszedzie,
              product_slug: this.$route.query.produkt,
              facility_type: facilityType,
              city,
              radius,
            })
          })
        }
      }
      if (searchType === 'product-all') {
        if (facilityType === 'stationary') {
          this.$router.push({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              szukaj_wszedzie: 1,
              typ_placowki: facilityType,
            },
          }).then(() => {
            this.searchStore.getProductSearchResults({
              empty_search: this.$route.query.szukaj_wszedzie,
              product_slug: this.$route.query.produkt,
              facility_type: facilityType,
              city,
              radius,
            })
          })
        } else if (facilityType === 'online') {
          this.$router.push({
            path: this.$route.path,
            query: {
              szukaj_wszedzie: 1,
              produkt: product_slug,
              typ_placowki: facilityType,
            },
          }).then(() => {
            this.searchStore.getProductSearchResults({
              empty_search: this.$route.query.szukaj_wszedzie,
              product_slug: this.$route.query.produkt,
              facility_type: facilityType,
            })
          })
        }
      } else if (searchType === 'localisation') {
        // SEARCH BY LOCALISATION
        this.searchStore.getLocalisationSearchResults({
          city,
          radius,
        })
      } else if (searchType === 'facilities') {
        // SEARCH BY FACILITIES
        const pushPath = this.$route.path === '/wyszukiwarka-lokalizacyjna' ? '/wyszukiwarka-lokalizacyjna' : '/agenci-ubezpieczeniowi'
        this.$router.push({
          path: pushPath,
          query: {
            typ_placowki: facilityType,
          },
        })
        this.searchStore.getAllFacilitiesSearchResults({
          facility_type: facilityType,
        })
      } else if (searchType === 'normal') {
        // NORMAL SEARCH (RESULTS / HOMEPAGE)
        this.searchStore.getSearchResults({
          search,
          city,
          radius,
          facility_type: facilityType,
        })
        const pushPath = (this.$route.path === '/' || this.$route.path === '/agenci-ubezpieczeniowi') ? 'szukaj' : this.$route.path
        this.$router.push({
          path: pushPath,
          query: {
            szukaj: search,
            typ_placowki: facilityType,
            ...(facilityType === 'stationary' && {
              miasto: city,
              odleglosc: radius,
            }),
          },
        })
      }
    },
    handleOptionClick(option) {
      if (option.type === 'agent') {
        this.$router.push(`/agencje-ubezpieczeniowe/agencja?id=${option.agent_facility_id}`)
        this.$refs['multiselect-search'].search = ' '
      } else if (option.type === 'agent_facility') {
        this.$router.push(`/agencje-ubezpieczeniowe/agencja?id=${option.facility_id}`)
        this.$refs['multiselect-search'].search = ' '
      } else if (option.type !== 'agent' && option.type !== 'agent_facility') {
        this.savedSearchOption = option
      }
    },
  },
  created() {
    this.autocomplete = _.debounce(this.autocomplete, 750)
    window.addEventListener('popstate', this.popstateEventAction)
  },
  mounted() {
    if (this.isCity) {
      const noOptionsSpan = document.querySelector('#city-no-options')
      if (noOptionsSpan) {
        const noOptionsLi = noOptionsSpan.parentNode.parentNode
        noOptionsLi.classList.add('display-none')
      }
    }
  },
}
</script>
