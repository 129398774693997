<template>
  <div class="site-loading">
    <img
      src="/assets/img/logo.svg"
      alt="logo"
      :class="'header__logo--image'"
    >
  </div>
</template>

<script>
export default {
  name: 'SiteLoading',
}
</script>
