<template>
  <section class="page-layout--bg-lightgrey py-6">
    <div class="container">
      <CommonTypographyHeader
        text1="Najnowsze"
        text2="na blogu"
        text2-primary
        :read-more="homepageArticles.length >= 1 && windowWidth > lg"
        read-more-link="/blog"
      />
      <div v-if="homepageArticles.length >= 1" class="blog-newest-advertisements">
        <BlogCard v-for="(data, i) in homepageArticles" :key="i" :data="data" stretch advertisement />
        <div v-if="windowWidth <= lg" class="mt-6 blog-newest-advertisements__read-more-button">
          <button class="button button--primary button--big" @click="handleRedirectToFacilities">
            <span class="text-bold text-no-decoration">Zobacz więcej artykułów</span>
          </button>
        </div>
      </div>
      <div v-if="homepageArticles.length === 0" class="center text-invalid">
        Nie znaleziono artykułów bloga.
      </div>
    </div>
  </section>
</template>

<script>
import { useBlogStore } from '../../store/blog'
import links from '../../mixins/links'
import global from '../../mixins/global'
import { useWindowWidth } from '../../composables'

export default {
  name: 'BlogNewestAdvertisements',
  mixins: [links, global],
  setup() {
    const blogStore = useBlogStore()
    const { windowWidth, lg } = useWindowWidth()

    return { blogStore, windowWidth, lg }
  },
  computed: {
    homepageArticles() {
      return this.blogStore.getHomepageArticles
    },
  },
}
</script>

<style scoped>

</style>
