<template>
  <section>
    <div class="container">
      <CommonTypographyHeader
        text1="Agencje Ubezpieczeniowe"
        :read-more="windowWidth > lg"
        :read-more-link="{ path: '/agenci-ubezpieczeniowi', query: { typ_placowki: isOnline ? 'online' : 'stationary' }}"
      />
      <div v-if="bestAgencies.length >= 1 && bestAgencies[0].imagesPlaceholderArray && bestAgencies[0].imagesPlaceholderArray.length === 1" class="insurance-agencies">
        <AgencyHomepageCard
          v-for="data in bestAgencies"
          :key="data.name"
          :data="data"
        />
        <div v-if="windowWidth <= lg" class="mt-6 insurance-agencies__read-more-button">
          <button class="button button--primary button--big" @click="handleRedirectToFacilities">
            <span class="text-bold text-no-decoration">Zobacz więcej placówek</span>
          </button>
        </div>
      </div>
      <div v-else>
        <p class="text-invalid text-center margin-bottom-2">
          Nie znaleziono placówek z najlepszymi agentami.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia/dist/pinia'
import { useBestAgentsStore } from '../../store/best-agents'
import { useWindowWidth } from '../../composables'
import { useSearchStore } from '../../store/search'

export default {
  name: 'InsuranceAgencies',
  setup() {
    const bestAgentsStore = useBestAgentsStore()
    const { windowWidth, lg } = useWindowWidth()
    const searchStore = useSearchStore()
    const { isOnline } = storeToRefs(searchStore)
    return { bestAgentsStore, windowWidth, lg, isOnline }
  },
  computed: {
    bestAgencies() {
      return this.bestAgentsStore.getBestAgentsLimited(6)
    },
  },
  methods: {
    handleRedirectToFacilities() {
      this.$router.push({
        path: '/agenci-ubezpieczeniowi',
        query: { typ_placowki: this.isOnline ? 'online' : 'stationary' },
      })
    },
  },
}
</script>
