<template>
  <div class="opinion-card">
    <div class="opinion-card__header">
      <div class="opinion-card__header--author">
        <h3 class="text-secondary-1">
          {{ data.name }}
        </h3>
      </div>
      <div class="opinion-card__header--rating stars">
        <span v-for="i in data.mark" :key="i" class="grade-star">
          <IconStar />
        </span>
      </div>
    </div>

    <div class="opinion-card__content">
      <span class="text-secondary-2 text-narrow">{{ data.content }}</span>
    </div>

    <div class="opinion-card__agency-name">
      <h3 class="text-secondary-1">
        {{ data.agent_facility_name }}
      </h3>
    </div>

    <div class="opinion-card__button">
      <button class="button button--secondary button--extra-small" @click="handleFacilityDetails(data.agent_facility_id)">
        <span class="text-extra-small text-bold">Zobacz placówkę</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpinionCard',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    handleFacilityDetails(id) {
      this.$router.push(`/agencje-ubezpieczeniowe/agencja?id=${id}`)
    },
  },
}
</script>
