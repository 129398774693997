export const searchDefaultOptions = [
  {
    type: 'insurance_category',
    name: 'Ubezpieczenia',
    subcategories: [
      {
        slug: 'komunikacyjne',
        type: 'insurance_subcategory',
        name: 'Komunikacyjne',
        description: 'Aut ratione corrupti assumenda praesentium et quisquam totam est. Enim aut et sit aut. Eius quae consequatur veritatis. Sit doloribus est cumque necessitatibus. Maiores sint est expedita rerum sit.',
        images: {
          icon: {
            svg: 'https://dobry-agent-dev.devinprox.pl/storage/1/6dc1e34b050177c462a807c343a27d80.svg',
          },
        },
      },
      {
        slug: 'domu-i-mieszkania',
        type: 'insurance_subcategory',
        name: 'Domu i mieszkania',
        description: 'Asperiores veniam asperiores provident qui nobis. Deleniti voluptas provident explicabo quaerat dicta perferendis. Quaerat deserunt velit amet qui ut voluptate hic. Sit qui repellat ut et velit.',
        images: {
          icon: {
            svg: 'https://dobry-agent-dev.devinprox.pl/storage/2/54d6fb7a66092ccdc6d62efe581c9848.svg',
          },
        },
      },
      {
        slug: 'nnw-szkolne',
        type: 'insurance_subcategory',
        name: 'NNW Szkolne',
        description: 'Laborum sed incidunt ducimus est a animi. Alias rerum quo nisi quod error. Placeat quas omnis commodi iure. Voluptates alias eligendi tempora aliquam.',
        images: {
          icon: {
            svg: 'https://dobry-agent-dev.devinprox.pl/storage/3/307bd48adbce7210201382930959e764.svg',
          },
        },
      },
      {
        slug: 'turystyczne',
        type: 'insurance_subcategory',
        name: 'Turystyczne',
        description: 'Qui eaque voluptas molestias quis quia. Fuga eum illum aut. Eum blanditiis saepe non eum. Enim minima veniam animi similique blanditiis aut velit.',
        images: {
          icon: {
            svg: 'https://dobry-agent-dev.devinprox.pl/storage/4/15f483d5decfb68e79c6e068c76d493a.svg',
          },
        },
      },
      {
        slug: 'zdrowotne',
        type: 'insurance_subcategory',
        name: 'Zdrowotne',
        description: 'Iure quidem dicta ipsam exercitationem. Ut voluptatem suscipit nemo assumenda. Perferendis omnis et dolorum nulla non magnam.',
        images: {
          icon: {
            svg: 'https://dobry-agent-dev.devinprox.pl/storage/5/cf0003d735c62cdffd51e6f7d02116e1.svg',
          },
        },
      },
      {
        slug: 'na-życie',
        type: 'insurance_subcategory',
        name: 'Na życie',
        description: 'Itaque iste enim aut voluptatem sint. Et expedita vero voluptate id occaecati asperiores laborum. Delectus omnis voluptas hic molestiae officia voluptatem blanditiis repudiandae.',
        images: {
          icon: {
            svg: 'https://dobry-agent-dev.devinprox.pl/storage/6/6343a9af50bab692e73213a0ae1638f9.svg',
          },
        },
      },
      {
        slug: 'firmowe',
        type: 'insurance_subcategory',
        name: 'Firmowe',
        description: 'Dicta et enim amet incidunt. Et voluptates dolores est. In est sapiente ratione voluptates. Pariatur totam repellat in qui tempora et ea. Officia ratione doloremque deleniti eum voluptas.',
        images: {
          icon: {
            svg: 'https://dobry-agent-dev.devinprox.pl/storage/7/5db7cba9c28860a3726e8e96480df102.svg',
          },
        },
      },
      {
        slug: 'rolne',
        type: 'insurance_subcategory',
        name: 'Rolne',
        description: 'Assumenda dolorum quas voluptatem illo consectetur minus inventore. Vitae a recusandae voluptas.',
        images: {
          icon: {
            svg: 'https://dobry-agent-dev.devinprox.pl/storage/8/aba6f191d240115f037a9ec636bd5f55.svg',
          },
        },
      },
      {
        slug: 'korporacyjne',
        type: 'insurance_subcategory',
        name: 'Korporacyjne',
        description: 'Tempora minima praesentium facere qui maxime molestiae. Sequi qui autem repudiandae blanditiis nihil explicabo. Cum aut qui aut.',
        images: {
          icon: {
            svg: 'https://dobry-agent-dev.devinprox.pl/storage/9/daa5438b961d210505870715aa72d08f.svg',
          },
        },
      },
    ],
  },
  {
    type: 'insurance_category',
    name: 'Kredyty',
    subcategories: [
      {
        slug: 'kredyt-hipoteczny',
        type: 'insurance_subcategory',
        name: 'Kredyt hipoteczny',
        description: 'Et fuga numquam nemo rerum fugit id. Et maiores illo aut temporibus qui provident laboriosam.',
        images: [],
      },
      {
        slug: 'leasing-samochodu',
        type: 'insurance_subcategory',
        name: 'Leasing samochodu',
        description: 'Explicabo aliquam dolor quas sit nemo. Autem iure quia sint a corporis aut alias. Est aut voluptatem dolores molestiae beatae aut id.',
        images: [],
      },
    ],
  },
  {
    type: 'insurance_category',
    name: 'Leasingi',
    subcategories: [
      {
        slug: 'leasing-samochodu',
        type: 'insurance_subcategory',
        name: 'Leasing samochodu',
        description: 'Rerum minus aut temporibus magnam rerum ratione omnis. Numquam eos eos perferendis dolore et. Maxime hic ut numquam et odit.',
        images: [],
      },
    ],
  },
  { type: 'insurance company', name: 'Towarzystwo ubezpieczeniowe', subcategories: [] },
  { type: 'agent', name: 'Agent', subcategories: [] },
  { type: 'agent_facility', name: 'Placówka', subcategories: [] },
]

export const citiesDefaultOptions = [
  {
    id: 1,
    name: 'Warszawa',
    voivodeship: 'Mazowieckie',
    district: 'Jadowniki-powiat',
    community: 'Kościerzyna-gmina',
  },
  {
    id: 2,
    name: 'Kraków',
    voivodeship: 'Małopolskie',
    district: 'Tychy-powiat',
    community: 'Kołobrzeg-gmina',
  },
  {
    id: 3,
    name: 'Łódź',
    voivodeship: 'Łódzkie',
    district: 'Mielec-powiat',
    community: 'Kamieniec Ząbkowicki-gmina',
  },
  {
    id: 4,
    name: 'Wrocław',
    voivodeship: 'Dolnośląskie',
    district: 'Puławy-powiat',
    community: 'Kłodzko-gmina',
  },
  {
    id: 5,
    name: 'Poznań',
    voivodeship: 'Wielkopolskie',
    district: 'Bezrzecze-powiat',
    community: 'Gliwice-gmina',
  },
  {
    id: 6,
    name: 'Gdańsk',
    voivodeship: 'Pomorskie',
    district: 'Nowe Kramsko-powiat',
    community: 'Pruszcz Gdański-gmina',
  },
  {
    id: 7,
    name: 'Szczecin',
    voivodeship: 'Zachodniopomorskie',
    district: 'Sanok-powiat',
    community: 'Świdnica-gmina',
  },
  {
    id: 8,
    name: 'Bydgoszcz',
    voivodeship: 'Kujawsko-pomorskie',
    district: 'Piotrków Trybunalski-powiat',
    community: 'Magdalenka-gmina',
  },
  {
    id: 9,
    name: 'Lublin',
    voivodeship: 'Lubelskie',
    district: 'Opole-powiat',
    community: 'Malbork-gmina',
  },
  {
    id: 10,
    name: 'Katowice',
    voivodeship: 'Śląskie',
    district: 'Warszawa-powiat',
    community: 'Łoś-gmina',
  },
  {
    id: 11,
    name: 'Białystok',
    voivodeship: 'Podlaskie',
    district: 'Kołobrzeg-powiat',
    community: 'Koszwały-gmina',
  },
  {
    id: 12,
    name: 'Gdynia',
    voivodeship: 'Pomorskie',
    district: 'Tarnowskie Góry-powiat',
    community: 'Chrzanów-gmina',
  },
  {
    id: 13,
    name: 'Częstochowa',
    voivodeship: 'Śląskie',
    district: 'Gniezno-powiat',
    community: 'Rumia-gmina',
  },
  {
    id: 14,
    name: 'Radom',
    voivodeship: 'Mazowieckie',
    district: 'Lubliniec-powiat',
    community: 'Suwałki-gmina',
  },
  {
    id: 15,
    name: 'Sosnowiec',
    voivodeship: 'Śląskie',
    district: 'Zgorzelec-powiat',
    community: 'Białystok-gmina',
  },
  {
    id: 16,
    name: 'Siedlce',
    voivodeship: 'Mazowieckie',
    district: 'Ciechanów-powiat',
    community: 'Kutno-gmina',
  },
]
export const cityDefault = citiesDefaultOptions[0]

export const radiusDefaultOptions = [
  { id: 1, radius: 10, name: '+10 km' },
  { id: 2, radius: 15, name: '+15 km' },
  { id: 3, radius: 20, name: '+20 km' },
  { id: 4, radius: 25, name: '+25 km' },
  { id: 5, radius: 30, name: '+30 km' },
  { id: 6, radius: 40, name: '+40 km' },
  { id: 7, radius: 50, name: '+50 km' },
]
export const radiusDefault = radiusDefaultOptions[6]

export const sortingDefaultOptions = [
  {
    name: 'Nazwa',
    method: 'name',
  },
  {
    name: 'Ocena',
    method: 'rating',
  },
  {
    name: 'Miasto',
    method: 'city',
  },
  // {
  //   name: 'Email',
  //   method: 'email',
  // },
]

export const sortingDefaultOptionsOnline = sortingDefaultOptions.filter(v => v.method !== 'city')
