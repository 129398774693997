import { acceptHMRUpdate, defineStore } from 'pinia'
import axios from 'axios'

export interface Opinion {
  id: number
  status: string
  report: string
  can_be_reported: number
  agent_id: number | null
  agent_facility_id: number | null
  agent_facility_name: string | null
  name: string
  email: string
  content: string
  mark: number
  useful: number | null
  unuseful: number | null
  useful_to_unuseful_ratio: number | null
  type?: string
}

export interface OpinionsState {
  randomOpinions: Opinion[]
  randomOpinionsDownloaded: boolean
  helpfulOpinions: Opinion[]
  notHelpfulOpinions: Opinion[]
  blockedOpinions: Opinion[]
}

export const useOpinionsStore = defineStore('opinions', {
  state(): OpinionsState {
    return {
      randomOpinions: [],
      randomOpinionsDownloaded: false,
      helpfulOpinions: [],
      notHelpfulOpinions: [],
      blockedOpinions: [],
    }
  },
  actions: {
    async getRandomOpinionsFromAPI() {
      if (this.randomOpinionsDownloaded) {
        return;
      }
      const opinionsResults = await axios.get(`${import.meta.env.VITE_API_URL}/v1/opinions`)

      this.randomOpinions = opinionsResults.data.data
      this.randomOpinionsDownloaded = true
    },
  },
  getters: {
    getRandomOpinions: (state: OpinionsState) => state.randomOpinions && state.randomOpinions,
    getOpinionById: (state: OpinionsState) => (id: number) => state.opinions && state.opinions.find(v => v.id === Number(id)),
    getHelpfulOpinions: (state: OpinionsState) => state.helpfulOpinions && state.helpfulOpinions,
    getNotHelpfulOpinions: (state: OpinionsState) => state.notHelpfulOpinions && state.notHelpfulOpinions,
  },
  persist: true,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOpinionsStore, import.meta.hot))
}
