import axios from 'axios'
import { acceptHMRUpdate, defineStore } from 'pinia'

export interface CommonState {
  privacyPolicy: string | null
  regulations: string | null
  rodo: string | null
}

export const useCommonStore = defineStore('common', {
  state(): CommonState {
    return {
      privacyPolicy: null,
      regulations: null,
      rodo: null,
    }
  },
  actions: {
    async getPrivacyPolicyFromAPI() {
      const res = await axios.get(`${import.meta.env.VITE_API_URL}/v1/get-page?page_slug=polityka-prywatnosci`)

      this.privacyPolicy = res.data.data.content
    },
    async getRegulationsFromAPI() {
      const res = await axios.get(`${import.meta.env.VITE_API_URL}/v1/get-page?page_slug=regulamin`)

      this.regulations = res.data.data.content
    },
    async getRodoFromAPI() {
      const res = await axios.get(`${import.meta.env.VITE_API_URL}/v1/get-page?page_slug=rodo`)

      this.rodo = res.data.data.content
    },
  },
  getters: {
    getPrivacyPolicy: state => state.privacyPolicy && state.privacyPolicy,
    getRegulations: state => state.regulations && state.regulations,
    getRodo: state => state.rodo && state.rodo,
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCommonStore, import.meta.hot))
}
