<template>
  <section class="py-10">
    <div class="container">
      <div class="opinions-newest-advertisements">
        <div class="opinions-newest-advertisements__container">
          <div class="opinions-newest-advertisements__container--header">
            <CommonTypographyHeader text1="Opinie" text2="o Agentach" text2-primary full-screen />
          </div>
          <div class="opinions-newest-advertisements__container--advertisements">
            <OpinionCard
              v-for="opinion in randomOpinions"
              :key="opinion.id"
              :data="opinion"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useOpinionsStore } from '../../store/opinions'

export default {
  name: 'OpinionNewestAdvertisements',
  setup() {
    const opinionsStore = useOpinionsStore()

    return { opinionsStore }
  },
  computed: {
    randomOpinions() {
      return this.opinionsStore.getRandomOpinions
    },
  },
}
</script>
