export default {
  methods: {
    isSomeRouteActive(name) {
      if (name === 'home') {
        return this.$route.path === '/'
      } else {
        return this.$route.path.includes(name)
      }
    },

    isRouteStartsWith(name) {
      if (name === '/home') {
        return this.$route.path === '/'
      } else {
        return this.$route.path.startsWith(name)
      }
    },

    handleRedirectToGoodAgent() {
      this.$router.push('/dobry-agent-poleca')
    },

    handleFacilityDetails(id) {
      this.$router.push({
        path: '/agencje-ubezpieczeniowe/agencja',
        query: {
          id,
        },
      })
    },

    handleProductDetails(slug) {
      this.$router.push({
        path: `/dobry-agent-poleca/${slug}`,
      })
    },

    handleRedirectToDetails(details) {
      const path = details.type === 'product'
        ? `/dobry-agent-poleca/${details.slug}`
        : '/blog/wpis'

      this.$router.push({
        path,
      })
    },
  },
}
