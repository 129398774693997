<template>
  <div class="blog-category-box" :class="{'blog-category-box--stretch' : stretch }">
    <picture v-if="data.images.image">
      <source :srcset="data.images.image.medium_webp" type="image/webp">
      <source :srcset="data.images.image.medium_png" type="image/png">
      <img
        :src="data.images.image.medium_png"
        :alt="data.extras.image_alt_text"
        :title="data.name"
        class="blog-category-box__img hover-pointer"
        @click="handleRedirectToArticle(data)"
      >
    </picture>
    <div class="blog-category-box__category-icon" :class="{ 'blog-category-box__category-icon--left-five-percent' : advertisement || stretch }">
      <img
        :srcset="data.category.images.icon.svg"
        :alt="data.category.name"
        :title="data.category.name"
        class="blog-category-box__category-image"
      >
    </div>

    <h3 class="blog-category-box__title">
      {{ data.title }}
    </h3>
    <p v-if="!advertisement" class="blog-category-box__description" v-html="data.short_content" />
    <RouterLink
      :to="`/blog/${data.category.slug}/${data.id}/${data.slug}`"
      class="blog-category-box__button button button--secondary button--extra-small text-no-decoration"
    >
      Zobacz szczegóły
    </RouterLink>
  </div>
</template>

<script>
import global from '../../mixins/global'

export default {
  name: 'BlogCard',
  mixins: [global],
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    advertisement: {
      type: Boolean,
      required: false,
      default: false,
    },
    stretch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
