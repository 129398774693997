import axios from 'axios'
import { acceptHMRUpdate, defineStore } from 'pinia'
import {
  citiesDefaultOptions,
  cityDefault,
  radiusDefault,
  radiusDefaultOptions,
  searchDefaultOptions,
  sortingDefaultOptions,
  sortingDefaultOptionsOnline,
} from './search-default-data'
import type { Facility } from '~/store/facility'
import { facilityImagePlaceholder } from '~/store/facility-default-data'
import type { Product } from '~/store/products'

export interface SearchSubcategory {
  slug: string
  type: string
  name: string
  description: string
  images: {
    icon: {
      svg: string
    }
  }
}

export interface SearchCategory {
  type: string
  name: string
  subcategories: SearchSubcategory[]
}

export interface City {
  id: number
  name: string
  voivodeship: string
  district: string
  community: string
}

export interface Radius {
  id: number
  radius: number
  name: string
}

export interface Sorting {
  name: string
  method: string
}

export interface SearchState {
  results: Facility[] | string
  resultsPages: {
    currentPage: number
    lastPage: number
  }
  extendedRadius: boolean
  search: SearchSubcategory | string | null
  city: City | null
  radius: Radius | null
  isOnline: boolean
  isInvalid: {
    search: boolean
    city: boolean
    radius: boolean
  }
  isTouched: {
    search: boolean
    city: boolean
    radius: boolean
  }
  categorySearchEmits: {
    emit: boolean
    value: SearchSubcategory
  }
  defaultSearchOptions: SearchCategory[]
  defaultSearchOptionsDownloaded: boolean
  defaultCityOptions: City[]
  defaultCityOptionsDownloaded: boolean
  defaultCity: City
  defaultRadiusOptions: Radius[]
  defaultRadius: Radius
  defaultSortingOptions: Sorting[]
  defaultSortingOptionsOnline: Sorting[]
}

export interface SearchDataRequest {
  search?: string
  city?: number
  radius?: number
  facility_type?: string
  page?: number
  empty_search?: number
  category_slug?: string
  product_slug?: string
}

export const getTodayBusinessHours = (businessHours) => {
  const today = new Date().getUTCDay()
  const dayNumber = today === 0 ? '7' : `${today}`
  return businessHours
    ? businessHours.find(v => v.day === dayNumber && v.open_time !== '' && v.close_time !== '')
    : 'Zamknięte'
}

export function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const useSearchStore = defineStore('search', {
  state(): SearchState {
    return {
      results: 'not-found',
      resultsPages: {
        currentPage: 1,
        lastPage: 11,
      },
      extendedRadius: true,
      search: null,
      city: null,
      radius: radiusDefault,
      isOnline: false,
      isInvalid: {
        search: false,
        city: false,
        radius: false,
      },
      isTouched: {
        search: false,
        city: false,
        radius: false,
      },
      categorySearchEmits: {
        emit: false,
        value: searchDefaultOptions[0].subcategories,
      },
      productSearchEmits: {
        emit: false,
        value: '',
      },
      defaultSearchOptions: searchDefaultOptions,
      defaultSearchOptionsDownloaded: false,
      defaultCityOptions: citiesDefaultOptions,
      defaultCityOptionsDownloaded: false,
      defaultCity: cityDefault,
      defaultRadiusOptions: radiusDefaultOptions,
      defaultRadius: radiusDefault,
      defaultSortingOptions: sortingDefaultOptions,
      defaultSortingOptionsOnline: sortingDefaultOptionsOnline,
    }
  },
  actions: {
    resetResults() {
      this.results = 'loading'
      this.resultsPages.currentPage = 1
      this.resultsPages.lastPage = 11
    },
    resetSearch() {
      this.search = null;
      this.city = null;
      this.isInvalid = {
        search: false,
        city: false,
        radius: false,
      };
      this.isTouched = {
        search: false,
        city: false,
        radius: false,
      };
    },
    async getSearchPlaceholders() {
      if (this.defaultSearchOptionsDownloaded) {
        return;
      }
      try {
        const res = await axios.get(`${import.meta.env.VITE_API_URL}/v1/search-placeholders`)
        this.defaultSearchOptions = res.data.data
        this.defaultSearchOptionsDownloaded = true
      } catch (error) {
        this.defaultSearchOptionsDownloaded = false
      }
    },
    async getDefaultCities() {
      if (this.defaultCityOptionsDownloaded) {
        return
      }
      try {
        const res = await axios.get(`${import.meta.env.VITE_API_URL}/v1/default-cities`)
        this.defaultCityOptions = res.data.data
        this.defaultCityOptionsDownloaded = true
      } catch (error) {
        this.defaultCityOptionsDownloaded = false
      }
    },
    async getSearchResults(searchData: SearchDataRequest) {
      const searchParams = searchData.facility_type === 'stationary'
        ? `?search=${searchData.search}&facility_type=${searchData.facility_type}&city=${searchData.city}&radius=${searchData.radius}`
        : `?search=${searchData.search}&facility_type=${searchData.facility_type}`

      const searchResults = await axios.get(`${import.meta.env.VITE_API_URL}/v1/search${searchParams}`)

      searchResults.data.data.forEach((v) => {
        v.rating = parseFloat(v.opinions_avg)
        v.today_business_hours = getTodayBusinessHours(v.business_hours)
        v.phone_number = v.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        v.imagesArray = Object.values(v.images)
        v.imagesPlaceholderArray = [facilityImagePlaceholder]
      })

      this.results = searchResults.data.data.length > 0 ? searchResults.data.data : 'not-found'
      this.resultsPages.currentPage = searchResults.data.meta.current_page
      this.resultsPages.lastPage = searchResults.data.meta.last_page
      this.extendedRadius = searchResults.data.extended_radius
    },
    async getMoreSearchResultsByPage(searchData: SearchDataRequest) {
      const searchParams = searchData.facility_type === 'stationary'
        ? `?search=${searchData.search}&facility_type=${searchData.facility_type}&city=${searchData.city}&radius=${searchData.radius}&page=${searchData.page}`
        : `?search=${searchData.search}&facility_type=${searchData.facility_type}&page=${searchData.page}`

      const moreSearchResults = await axios.get(`${import.meta.env.VITE_API_URL}/v1/search${searchParams}`)

      moreSearchResults.data.data.forEach((v) => {
        v.rating = parseFloat(v.opinions_avg)
        v.today_business_hours = getTodayBusinessHours(v.business_hours)
        v.phone_number = v.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        v.imagesArray = Object.values(v.images)
        v.imagesPlaceholderArray = [facilityImagePlaceholder]
      })

      moreSearchResults.data.data.length > 0
        ? this.results = [...this.results, ...moreSearchResults.data.data]
        : null

      this.resultsPages.currentPage = moreSearchResults.data.meta.current_page
      this.resultsPages.lastPage = moreSearchResults.data.meta.last_page
      this.extendedRadius = moreSearchResults.data.extended_radius
    },

    async getLocalisationSearchResults(searchData: SearchDataRequest) {
      const searchResults = await axios.get(
        `${import.meta.env.VITE_API_URL}/v1/localization-search?city=${searchData.city}&radius=${searchData.radius}`)

      searchResults.data.data.forEach((v) => {
        v.rating = parseFloat(v.opinions_avg)
        v.today_business_hours = getTodayBusinessHours(v.business_hours)
        v.phone_number = v.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        v.imagesArray = Object.values(v.images)
        v.imagesPlaceholderArray = [facilityImagePlaceholder]
      })

      this.results = searchResults.data.data.length > 0 ? searchResults.data.data : 'not-found'
      this.resultsPages.currentPage = searchResults.data.meta.current_page
      this.resultsPages.lastPage = searchResults.data.meta.last_page
      this.extendedRadius = searchResults.data.extended_radius
    },

    async getMoreLocalisationSearchResultsByPage(searchData: SearchDataRequest) {
      const moreSearchResults = await axios.get(
        `${import.meta.env.VITE_API_URL}/v1/localization-search?city=${searchData.city}&radius=${searchData.radius}&page=${searchData.page}`)

      moreSearchResults.data.data.forEach((v) => {
        v.rating = parseFloat(v.opinions_avg)
        v.today_business_hours = getTodayBusinessHours(v.business_hours)
        v.phone_number = v.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        v.imagesArray = Object.values(v.images)
        v.imagesPlaceholderArray = [facilityImagePlaceholder]
      })

      moreSearchResults.data.data.length > 0
        ? this.results = [...this.results, ...moreSearchResults.data.data]
        : null

      this.resultsPages.currentPage = moreSearchResults.data.meta.current_page
      this.resultsPages.lastPage = moreSearchResults.data.meta.last_page
      this.extendedRadius = moreSearchResults.data.extended_radius
    },

    async getAllFacilitiesSearchResults(searchData: SearchDataRequest) {
      const facilitiesResults = await axios.get(`${import.meta.env.VITE_API_URL}/v1/facilities?facility_type=${searchData.facility_type}`)

      facilitiesResults.data.data.forEach((v) => {
        v.rating = parseFloat(v.opinions_avg)
        v.today_business_hours = getTodayBusinessHours(v.business_hours)
        v.phone_number = v.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        v.imagesArray = Object.values(v.images)
        v.imagesPlaceholderArray = [facilityImagePlaceholder]
      })

      this.results = facilitiesResults.data.data.length > 0 ? facilitiesResults.data.data : 'not-found'
      this.resultsPages.currentPage = facilitiesResults.data.meta.current_page
      this.resultsPages.lastPage = facilitiesResults.data.meta.last_page
      this.extendedRadius = facilitiesResults.data.extended_radius
    },
    async getMoreAllFacilitiesSearchResultsByPage(searchData: SearchDataRequest) {
      const moreFacilitiesResults = await axios.get(`${import.meta.env.VITE_API_URL}/v1/facilities?facility_type=${searchData.facility_type}&page=${searchData.page}`)

      moreFacilitiesResults.data.data.forEach((v) => {
        v.rating = parseFloat(v.opinions_avg)
        v.today_business_hours = getTodayBusinessHours(v.business_hours)
        v.phone_number = v.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        v.imagesArray = Object.values(v.images)
        v.imagesPlaceholderArray = [facilityImagePlaceholder]
      })

      moreFacilitiesResults.data.data.length > 0
        ? this.results = [...this.results, ...moreFacilitiesResults.data.data]
        : null

      this.resultsPages.currentPage = moreFacilitiesResults.data.meta.current_page
      this.resultsPages.lastPage = moreFacilitiesResults.data.meta.last_page
      this.extendedRadius = moreFacilitiesResults.data.extended_radius
    },

    async getDefaultCityFromAPI() {
      const citiesResult = await axios.get(`${import.meta.env.VITE_API_URL}/v1/cities?city=Warszawa`)
      this.defaultCity = citiesResult.data.data.find(v => v.name === 'Warszawa' && v.voivodeship === 'Mazowieckie')
    },

    async setCategoryEmits(emit: boolean, value: SearchSubcategory) {
      this.categorySearchEmits.emit = emit
      this.categorySearchEmits.value = value
    },

    async getCategorySearchResults(searchData: SearchDataRequest) {
      const searchParams = searchData.facility_type === 'stationary'
        ? `?empty_search=${searchData.empty_search}&category_slug=${searchData.category_slug}&facility_type=${searchData.facility_type}&city=${searchData.city}&radius=${searchData.radius}`
        : `?empty_search=${searchData.empty_search}&category_slug=${searchData.category_slug}&facility_type=${searchData.facility_type}`

      const searchResults = await axios.get(`${import.meta.env.VITE_API_URL}/v1/search-by-category${searchParams}`)

      searchResults.data.data.forEach((v) => {
        v.rating = parseFloat(v.opinions_avg)
        v.today_business_hours = getTodayBusinessHours(v.business_hours)
        v.phone_number = v.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        v.imagesArray = Object.values(v.images)
        v.imagesPlaceholderArray = [facilityImagePlaceholder]
      })

      this.results = searchResults.data.data.length > 0 ? searchResults.data.data : 'not-found'
      this.resultsPages.currentPage = searchResults.data.meta.current_page
      this.resultsPages.lastPage = searchResults.data.meta.last_page
      this.extendedRadius = searchResults.data.extended_radius
    },
    async getMoreCategorySearchResultsByPage(searchData: SearchDataRequest) {
      const searchParams = searchData.facility_type === 'stationary'
        ? `?empty_search=${searchData.empty_search}&category_slug=${searchData.category_slug}&facility_type=${searchData.facility_type}&city=${searchData.city}&radius=${searchData.radius}&page=${searchData.page}`
        : `?empty_search=${searchData.empty_search}&category_slug=${searchData.category_slug}&facility_type=${searchData.facility_type}&page=${searchData.page}`

      const moreSearchResults = await axios.get(`${import.meta.env.VITE_API_URL}/v1/search-by-category${searchParams}`)

      moreSearchResults.data.data.forEach((v) => {
        v.rating = parseFloat(v.opinions_avg)
        v.today_business_hours = getTodayBusinessHours(v.business_hours)
        v.phone_number = v.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        v.imagesArray = Object.values(v.images)
        v.imagesPlaceholderArray = [facilityImagePlaceholder]
      })

      moreSearchResults.data.data.length > 0
        ? this.results = [...this.results, ...moreSearchResults.data.data]
        : null

      this.resultsPages.currentPage = moreSearchResults.data.meta.current_page
      this.resultsPages.lastPage = moreSearchResults.data.meta.last_page
      this.extendedRadius = moreSearchResults.data.extended_radius
    },

    async setProductEmits(emit: boolean, value: Product) {
      this.productSearchEmits.emit = emit
      this.productSearchEmits.value = value
    },

    async getProductSearchResults(searchData: SearchDataRequest) {
      const searchParams = searchData.facility_type === 'stationary'
        ? `?empty_search=${searchData.empty_search}&product_slug=${searchData.product_slug}&facility_type=${searchData.facility_type}&city=${searchData.city}&radius=${searchData.radius}`
        : `?empty_search=${searchData.empty_search}&product_slug=${searchData.product_slug}&facility_type=${searchData.facility_type}`

      const searchResults = await axios.get(`${import.meta.env.VITE_API_URL}/v1/search-by-product${searchParams}`)

      searchResults.data.data.forEach((v) => {
        v.rating = parseFloat(v.opinions_avg)
        v.today_business_hours = getTodayBusinessHours(v.business_hours)
        v.phone_number = v.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        v.imagesArray = Object.values(v.images)
        v.imagesPlaceholderArray = [facilityImagePlaceholder]
      })

      this.results = searchResults.data.data.length > 0 ? searchResults.data.data : 'not-found'
      this.resultsPages.currentPage = searchResults.data.meta.current_page
      this.resultsPages.lastPage = searchResults.data.meta.last_page
      this.extendedRadius = searchResults.data.extended_radius
    },
    async getMoreProductSearchResultsByPage(searchData: SearchDataRequest) {
      const searchParams = searchData.facility_type === 'stationary'
        ? `?empty_search=${searchData.empty_search}&product_slug=${searchData.product_slug}&facility_type=${searchData.facility_type}&city=${searchData.city}&radius=${searchData.radius}&page=${searchData.page}`
        : `?empty_search=${searchData.empty_search}&product_slug=${searchData.product_slug}&facility_type=${searchData.facility_type}&page=${searchData.page}`

      const moreSearchResults = await axios.get(`${import.meta.env.VITE_API_URL}/v1/search-by-product${searchParams}`)

      moreSearchResults.data.data.forEach((v) => {
        v.rating = parseFloat(v.opinions_avg)
        v.today_business_hours = getTodayBusinessHours(v.business_hours)
        v.phone_number = v.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        v.imagesArray = Object.values(v.images)
        v.imagesPlaceholderArray = [facilityImagePlaceholder]
      })

      moreSearchResults.data.data.length > 0
        ? this.results = [...this.results, ...moreSearchResults.data.data]
        : null

      this.resultsPages.currentPage = moreSearchResults.data.meta.current_page
      this.resultsPages.lastPage = moreSearchResults.data.meta.last_page
      this.extendedRadius = moreSearchResults.data.extended_radius
    },

  },
  getters: {
    getResults: state => state.results,
    getResultById: state => id => state.results && state.results.find(v => v.id === Number(id)),
    getResultsPages: state => state.resultsPages,
    getExtendedRadius: state => state.extendedRadius && state.extendedRadius,
    getSearchEmitsCategory: state => state.categorySearchEmits && state.categorySearchEmits,
    getDefaultCity: state => state.defaultCity && state.defaultCity,
    getDefaultSearchOptions: state => state.defaultSearchOptions && state.defaultSearchOptions,
    getDefaultSortingOptions: state => state.defaultSortingOptions && state.defaultSortingOptions,
    getDefaultSortingOptionsOnline: state => state.defaultSortingOptionsOnline && state.defaultSortingOptionsOnline,
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot))
}
