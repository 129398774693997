<template>
  <div v-if="isShow" class="cookie-modal">
    <p>
      Ta strona korzysta z ciasteczek (pliki cookies) aby świadczyć usługi na najwyższym poziomie. Korzystając ze strony wyrażasz zgodę na ich używanie zgodnie z aktualnymi ustawieniami przeglądarki. Szczegółowe informacje znajdują się w <RouterLink to="/polityka-prywatnosci" class="cookie-modal__link">Polityce prywatności.</RouterLink>
    </p>
    <button
      class="button button--secondary button--search-agents"
      @click="closeModal(true)"
    >
      <span>Rozumiem</span>
    </button>
  </div>
</template>

<script>
import { useCookies } from 'vue3-cookies'

export default {
  name: 'CookieModal',
  setup() {
    const { cookies } = useCookies()

    return { cookies }
  },
  data() {
    return {
      isShow: true,
    }
  },
  watch: {
    $route: {
      immediate: false,
      deep: true,
      handler(nV, oV) {
        if (nV.path !== oV.path) {
          this.checkCookie()
        }
      },
    },
  },
  mounted() {
    this.checkCookie()
  },
  methods: {
    showModal() {
      this.isShow = true
    },
    closeModal(accepted) {
      this.isShow = false

      if (accepted) {
        this.cookies.set('cookie_consent', '1')
      }
    },
    checkCookie() {
      if (this.cookies.isKey('cookie_consent') && this.cookies.get('cookie_consent') === '1') {
        this.closeModal(true)
      } else {
        this.showModal()
      }
    },
  },
}
</script>
